<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="white"
      elevation="1"
      height="80"
    > 
    <base-img
        src="\img\icons\android-chrome-192x192.png"
        class="mr-3 hidden-xs-only"
        contain
        max-width="52"
        width="100%"
      />
      Monitor Satelital
       <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
        >
          <v-tab
            v-for="(itm, i) in items.filter(it => !(it.check && !isAuth))"
            :key="i"
            :to="itm.url"
            :exact="itm.name === '/'"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ itm.name }}
          </v-tab>


          <v-tab             
          v-if="isAuth">
          <v-menu
            offset-y
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="item.url"
                v-for="(item, i) in itemsProfile.filter(it => !(it.check && !isAdmin))"
                :key="i"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="getDrawerItems"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },
    computed:{
      ...mapGetters(['isAuth','isAdmin'])
      ,getDrawerItems(){
        return this.items.concat(this.isAuth?this.itemsProfile:[]);
      },
    },
    data: () => ({
      drawer: null,
      items: [
        {name:'Home',url:'/'},
        {name:'Nosotros',url:'/about'},
        {name:'Preguntas',url:'/faq'},
        {name:'Vigilante',url:'/app'},
        {name:'History',url:'/history',check:true},
      ],
      itemsProfile: [
        {name:'Perfil',url:'/profile'},
        {name:'Mis Dispositivos',url:'/devices'},
        {name:'Users',url:'/users',check:true},
        {name:'Devices',url:'/devicelist',check:true},
        {name:'Support',url:'/support',check:true},
        {name:'Salir',url:'/signout'},
      ],
    }),
}
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>